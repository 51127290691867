// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".information-hotel{margin-top:0.75rem;display:flex;flex-direction:column;}\n\n.form-info{width:24rem;border-radius:1.5rem;--tw-bg-opacity:1;background-color:rgb(186 230 253 / var(--tw-bg-opacity));}\n\n.button-trans{margin-top:1.5rem;display:flex;justify-content:center;}\n\n.pointer{cursor:pointer;}", "",{"version":3,"sources":["webpack://./src/styles/stylesMap/insertupdate.css"],"names":[],"mappings":"AAGI,mBAAA,kBAAwB,CAAxB,YAAwB,CAAxB,sBAAwB;;AAIxB,WAAA,WAAkC,CAAlC,oBAAkC,CAAlC,iBAAkC,CAAlC,yDAAkC;;AAIlC,cAAA,iBAA8B,CAA9B,YAA8B,CAA9B,uBAA8B;;AAGlC,SAAS,cAAc,CAAC","sourcesContent":["@import './index.css';\n\n.information-hotel{\n    @apply flex flex-col mt-3\n}\n\n.form-info{\n    @apply  bg-sky-200 rounded-3xl w-96\n}\n\n.button-trans{\n    @apply flex justify-center mt-6\n}\n\n.pointer{cursor:pointer;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
