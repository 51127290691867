// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../node_modules/leaflet/dist/leaflet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContainerMap{\n    position: relative;\n    height: 100%;\n    width: 100%;  \n}\n\n.MiniMap{\n    position: relative;\n    height: 450px;\n    width: 750px;   \n}\n\n.leaflet-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/styles/stylesMap/map.css"],"names":[],"mappings":"AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB","sourcesContent":["@import 'leaflet/dist/leaflet.css';\n\n.ContainerMap{\n    position: relative;\n    height: 100%;\n    width: 100%;  \n}\n\n.MiniMap{\n    position: relative;\n    height: 450px;\n    width: 750px;   \n}\n\n.leaflet-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
